<template>
    <div data-app>
        <template>
            <v-container fluid>
                <v-row class="ma-0">
                    <v-col class="pb-4 pl-0">
                        <span class="text-h5">Tìm kiếm</span>
                    </v-col>
                </v-row>
                <v-row class="ma-0">
                    <v-col cols="6" class="pa-0">
                        <v-row class="ma-0 pt-3">
                            <v-col cols="3" class="pa-0">
                                <span class="text-body-1">Tên bài viết</span>
                            </v-col>
                            <v-col cols="9" class="pa-0">
                                <hnr-input @active="search" v-model="lesson_name"></hnr-input>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-row class="align-start ma-0">
                            <v-col cols="1"></v-col>
                            <v-col cols="3" class="pa-0 mt-3">
                                <span class="text-body-1">Thời gian</span>
                            </v-col>
                            <v-col cols="4" class="pa-0">
                                <v-menu
                                        v-model="startDialog"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="startDate"
                                                label="Thời gian bắt đầu"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="startDate"
                                            @input="startDialog = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="4" class="pa-0">
                                <v-menu
                                        v-model="endDialog"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="endDate"
                                                label="Thời gian kết thúc"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="endDate"
                                            @input="endDialog = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="ma-0">
                    <v-col cols="6" class="pa-0">
                        <v-row class="ma-0 pt-3">
                            <v-col cols="3" class="pa-0">
                                <span class="text-body-1">Người tạo</span>
                            </v-col>
                            <v-col cols="9" class="pa-0">
                                <v-select
                                        :items="users"
                                        item-text="full_name"
                                        item-value="full_name"
                                        v-model="publish_by"
                                        label="Chọn người viết bài"
                                        solo
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-row class="ma-0">
                            <v-col cols="1"></v-col>
                            <v-col cols="3" class="pa-0 mt-4">
                                <span class="text-body-1">Chưa có chủ đề</span>
                            </v-col>
                            <v-col cols="8" class="pa-0">
                                <v-checkbox v-model="hasNotYetCategorySearch"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="align-center justify-center ma-0">
                    <v-col cols="6" class="pa-0">
                        <v-btn color="primary" @click="search">Tìm kiếm</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-data-table
                    :headers="headers"
                    :items="lessons"
                    class="elevation-1"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    :server-items-length="total"
                    @update:items-per-page="updateItemPerPage"
                    @update:page="updatePage"
                    :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
            >
                <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-card-title class="text-h5 pa-0">
                            Danh sách bài viết
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" dark class="mb-2" to="/lesson/add" target="_blank"
                        >Thêm mới
                        </v-btn
                        >
                    </v-toolbar>
                </template>

                <!-- <template v-slot:item.category="{ item }">
                  <span v-if="item.category">{{ item.category.name }}</span>
                </template> -->

                <template v-slot:item.created_at="{ item }">
                    <span>{{ formatDateTime(item.created_at) }}</span>
                </template>
                <template v-slot:item.status="{ item }">
                    <v-switch v-model="item.status" :disabled="true"></v-switch>
                </template>
                <template v-slot:item.act="{ item }">
                    <v-switch v-model="item.act" :disabled="true"></v-switch>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                        Your search for "{{ search }}" found no results.
                    </v-alert>
                </template>
            </v-data-table>
        </template>
        <!-- DIALOG DELETE  -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline"
                >Are you sure you want to delete this item?
                </v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel
                    </v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {COUNT_LESSON, GET_LIST_LESSONS, DELETE_LESSON} from "@/store/lesson.module";
import {validationMixin} from "vuelidate";
import HnrInput from "@/components/elements/hnr-input";
import {SET_LOADING} from "@/store/loading.module";
import {ERROR} from "@/store/alert.module";
import {GET_LIST_USERS} from "@/store/user.module";

export default {
    mixins: [validationMixin],
    components: {
        HnrInput,
    },
    data: () => ({
        page: 1,
        itemsPerPage: 10,
        headers: [
            {
                text: "ID",
                align: "start",
                sortable: false,
                value: "id",
            },
            {text: "Tên bài viết", value: "name", width: "30%"},
            {text: "Slug", value: "slug", width: "20%"},
            // {text: "Chủ đề cha", value: "topics.name", width: "15%"},
            {text: "Người tạo", value: "publish_by"},
            {text: "Active", value: "status"},
            {text: "Ngày tạo", value: "created_at"},
            {text: "Actions", value: "actions", sortable: false},
        ],
        publish_by: "",
        lesson_name: "",
        category: "",
        startDate: "",
        endDate: "",
        startDialog: "",
        endDialog: "",
        hasNotYetCategorySearch: false,
        dialog: false,
        dialogDelete: false,
    }),

    computed: {
        ...mapGetters({
            lessons: "listLessons",
            users: "users",
            total: "lessonCount"
        }),
    },
    mounted() {
        this.getLessons();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Bài học", route: "Bài học"},
        ]);

        this.$store.dispatch(GET_LIST_USERS, {authority: 1})
    },
    watch: {},
    methods: {
        search() {
            this.getLessons();
        },
        getLessons() {
            if ((!this.startDate && this.endDate) || (this.startDate && !this.endDate)) {
                this.$store.dispatch(ERROR, 'Điền thời gian bắt đầu và kết thúc', {
                    root: true,
                });
                return
            }
            let payload = {
                publish_by: this.publish_by,
                name: this.lesson_name,
                date: this.startDate && this.endDate ? this.startDate + ',' + this.endDate : null,
                parent_id: this.hasNotYetCategorySearch ? 'null' : null,
                page: this.page,
                per_page: this.itemsPerPage,
            };
            this.$store.dispatch(SET_LOADING, true);
            this.$store
                .dispatch(GET_LIST_LESSONS, payload)
                .then(() => {
                    this.$store.dispatch(SET_LOADING, false);
                })
                .catch(() => {
                    this.$store.dispatch(SET_LOADING, false);
                });
        },

        updateItemPerPage($event) {
            this.itemsPerPage = $event;
            this.getLessons();
        },

        updatePage($event) {
            this.page = $event;
            this.getLessons();
        },

        editItem(item) {
            let routeData = this.$router.resolve({
                name: "lession-detail",
                params: {slug: item.slug},
            });
            window.open(routeData.href, '_blank');
        },

        deleteItem(item) {
            this.editedIndex = this.lessons.indexOf(item);
            this.editedId = item.id;
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.$store.dispatch(DELETE_LESSON, this.editedId).then((data) => {
                if (data.status) {
                    this.lessons.splice(this.editedIndex, 1);
                } else {
                    if (data.errorCode == 424) {
                        this.$store.dispatch(ERROR, data.message, {
                            root: true,
                        });
                    } else {
                        this.$store.dispatch(ERROR, "Xóa thất bại!", {
                            root: true,
                        });
                    }

                }
            });

            this.closeDelete();
        },
        closeDelete() {
            this.dialogDelete = false;
        },

        addNewItem() {
            this.$router.push({name: "lession-add"});
        },
        formatDateTime(date) {
            if (!date) return null
            const newDate = new Date(date)
            const [year, month, day, hour, minute, millisecond] = [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate(),
                newDate.getHours(), newDate.getMinutes(), newDate.getMilliseconds()]
            const hour_format = hour > 9 ? hour : `0${hour}`
            const minute_format = minute > 9 ? minute : `0${minute}`
            const millisecond_format = millisecond > 9 ? millisecond : `0${millisecond}`
            return `${hour_format}:${minute_format}:${millisecond_format} ${day}/${month}/${year}`
        },
    }
    ,
}
;
</script>
